import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { message } from "antd";

const initialState = {
  // all investors
  allInvestors: null,
  allInvestorsLoading: false,

  // single investor
  singleInvestor: null,
  singleInvestorLoading: false,

  // export investors
  investorsCSVLoading: false,

  // all investments
  allInvestments: null,
  allInvestmentsLoading: false,

  // update investment
  updateInvestmentLoading: false,

  // export investments
  investmentsCSVLoading: false,

  //Legal docs Loading
  companyDocsLoading: false,
};


//^ ========= get all investors  ============ //
export const getAllInvestors = createAsyncThunk("investors/getAllInvestors", async (params) => {
  try {
    return await axiosInstance
      .get(`/admin/get_investors`, { params })
      .then((response) => {
        console.log(response, "getAllInvestors");
        return response?.data?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    throw e;
  }
});

//^ ========= get single investor  ============ //
export const getSingleInvestor = createAsyncThunk("investors/getSingleInvestor", async (id) => {
  try {
    return await axiosInstance
      .get(`/admin/get_single_investor/${id}`)
      .then((response) => {
        console.log(response?.data?.data, "res single investor**************");
        return response?.data?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    throw e;
  }
});


//^ ========= get investors csv  ============ //
export const getInvestorsCSV = createAsyncThunk("investors/getInvestorsCSV", async (data) => {
  try {
    return await axiosInstance
      .post(`/admin/export-investors-csv`, data)
      .then((response) => {
        console.log(response, "getInvestorsCSV");
        const link = document.createElement("a");
        link.href = response?.data?.data;
        link.download = "investors.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return response?.data?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    throw e;
  }
});


//^ ========= Investor update info  ============
export const postInvestorInfo = createAsyncThunk(
  "investors/postInvestorInfo",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`/admin/update_individual_investor`, data)
        .then((response) => {
          message.success(response?.data?.message);
          dispatch(getSingleInvestor(data?.user_id));
          console.log(response, "update");
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= institutional kyc details ============
export const postInstitutionalKycDetails = createAsyncThunk(
  "investors/postInstitutionalKycDetails",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`/admin/update-users-documents`, data)
        .then((response) => {
          message.success(response?.data?.message);
          dispatch(getSingleInvestor(data?.user_id));
          console.log(response, "update");
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= company docs info  ============
export const postLegalDocs = createAsyncThunk(
  "investors/postLegalDocs",
  async ({ formData, id }, { rejectWithValue, dispatch }) => {
    try {
      axiosInstance.defaults.headers.post["Content-Type"] = "multipart/form-data";
      return await axiosInstance
        .post(`store-investor-documents`, formData)
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response, "investor-----------------")
          dispatch(getSingleInvestor(id));
          console.log(response, "update");
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);


//^ ========= UPDATE expire date DOC   ============
export const postExpireDate = createAsyncThunk(
  "investors/postExpireDate",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`/admin/update-users-documents`, data)
        .then((response) => {
          console.log(response, "update---------------------------- store");
          message.success(response?.data?.message);
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);
//^ ========= individual kyc details ============
export const postIndividualKycDetails = createAsyncThunk(
  "investors/postIndividualKycDetails",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`/admin/individual-kyc-details`, data)
        .then((response) => {
          message.success(response?.data?.message);
          dispatch(getSingleInvestor(data?.user_id));
          console.log(response, "update");
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= get all investors  ============ //
export const getAllInvestments = createAsyncThunk("investors/getAllInvestments", async (params) => {
  try {
    return await axiosInstance
      .get(`admin/get_investments`, { params })
      .then((response) => {
        console.log(response, "getAllInvestments");
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    throw e;
  }
});

//^ ========= update investments  ============ //
export const postUpdateInvestmentStatus = createAsyncThunk("investors/postUpdateInvestmentStatus", async (data, { rejectWithValue, dispatch }) => {
  try {
    return await axiosInstance
      .post(`/admin/update_investment`, data)
      .then((response) => {
        dispatch(getAllInvestments())
        console.log(response, "getAllInvestments");
        return response?.data?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    throw e;
  }
});

//^ ========= get investors csv  ============ //
export const getInvestmentsCSV = createAsyncThunk("investors/getInvestmentsCSV", async () => {
  try {
    return await axiosInstance
      .get(`/admin/export_investments`)
      .then((response) => {
        console.log(response, "getInvestorsCSV");
        const link = document.createElement("a");
        link.href = response?.data?.data?.fileUrl;
        link.download = "investors.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return response?.data?.data?.fileUrl;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    throw e;
  }
});
/*-----------------------Slice----------------*/

const InvestorsSlice = createSlice({
  name: "investorsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // all investors
    builder.addCase(getAllInvestors.pending, (state) => {
      state.allInvestorsLoading = true;
    });
    builder.addCase(getAllInvestors.fulfilled, (state, action) => {
      state.allInvestorsLoading = false;
      state.allInvestors = action.payload;
    });
    builder.addCase(getAllInvestors.rejected, (state) => {
      state.allInvestorsLoading = false;
    });

    // single investors
    builder.addCase(getSingleInvestor.pending, (state) => {
      state.singleInvestorLoading = true;
    });
    builder.addCase(getSingleInvestor.fulfilled, (state, action) => {
      state.singleInvestorLoading = false;
      state.singleInvestor = action.payload;
    });
    builder.addCase(getSingleInvestor.rejected, (state) => {
      state.singleInvestorLoading = false;
    });

    // investors csv
    builder.addCase(getInvestorsCSV.pending, (state) => {
      state.investorsCSVLoading = true;
    });
    builder.addCase(getInvestorsCSV.fulfilled, (state, action) => {
      state.investorsCSVLoading = false;
    });
    builder.addCase(getInvestorsCSV.rejected, (state) => {
      state.investorsCSVLoading = false;
    });

    // all investments 
    builder.addCase(getAllInvestments.pending, (state) => {
      state.allInvestmentsLoading = true;
    });
    builder.addCase(getAllInvestments.fulfilled, (state, action) => {
      state.allInvestmentsLoading = false;
      state.allInvestments = action.payload;
    });
    builder.addCase(getAllInvestments.rejected, (state) => {
      state.allInvestmentsLoading = false;
    });

    // update investments status
    builder.addCase(postUpdateInvestmentStatus.pending, (state) => {
      state.updateInvestmentLoading = true;
    });
    builder.addCase(postUpdateInvestmentStatus.fulfilled, (state, action) => {
      state.updateInvestmentLoading = false;
      // state.allInvestments = action.payload;
    });
    builder.addCase(postUpdateInvestmentStatus.rejected, (state) => {
      state.updateInvestmentLoading = false;
    });

    // investors csv
    builder.addCase(getInvestmentsCSV.pending, (state) => {
      state.investmentsCSVLoading = true;
    });
    builder.addCase(getInvestmentsCSV.fulfilled, (state, action) => {
      state.investmentsCSVLoading = false;
    });
    builder.addCase(getInvestmentsCSV.rejected, (state) => {
      state.investmentsCSVLoading = false;
    });

    // company Docs
    builder.addCase(postLegalDocs.pending, (state) => {
      state.legalDocsLoading = true;
    });
    builder.addCase(postLegalDocs.fulfilled, (state, action) => {
      state.legalDocsLoading = false;
    });
    builder.addCase(postLegalDocs.rejected, (state) => {
      state.legalDocsLoading = false;
    });

  },
});

export default InvestorsSlice.reducer;
