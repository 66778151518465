import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { message } from "antd";

const initialState = {
  // cr number 
  wathqDataLoading: false,
  wathqData: null,

  // wallets transactions
  walletTransactionsLoading: false,
  walletTransactions: null,

  // wallets transactions csvs
  walletsCsvsLoading: false
};

//^ ========= postCrNumber ============
export const postCrNumber = createAsyncThunk("general/postCrNumber", async (data, { rejectWithValue, dispatch }) => {
  try {
    return await axiosInstance
      .post("/search-registration-number", data)
      .then((response) => {
        message.success("success");
        console.log(response.data.data);
        return response?.data?.data;
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  } catch (e) {
    throw e;
  }
});

//^ ========= wallets transactions  ============
export const getUserWalletTransactions = createAsyncThunk(
  "general/getUserWalletTransactions",
  async (params, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`/wallet/transactions`, { params })
        .then((response) => {
          return response?.data?.data;
        })
        .catch((error) => {
          console.log(error);
          // return rejectWithValue({
          //   message: error.message,
          //   error: error.response.data,
          // });
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= wallets transactions csvs  ============
export const getWalletTransactionsCSV = createAsyncThunk(
  "general/getWalletTransactionsCSV",
  async (params, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`/wallet/export-csv-transactions`, params)
        .then((response) => {
          console.log(response?.data?.data?.fileUrl, "opportunity csv");
          const a = document.createElement("a");
          a.href = response?.data?.data?.fileUrl;
          a.download = "lean_transactions.csv";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          return response?.data?.data?.fileUrl;
        })
        .catch((error) => {
          console.log(error);
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);
/*-----------------------Slice----------------*/

const GeneralSliceSlice = createSlice({
  name: "faqsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // update faqs
    builder.addCase(postCrNumber.pending, (state) => {
      state.wathqDataLoading = true;
    });
    builder.addCase(postCrNumber.fulfilled, (state, action) => {
      state.wathqDataLoading = false;
      state.wathqData = action.payload;
    });
    builder.addCase(postCrNumber.rejected, (state) => {
      state.wathqDataLoading = false;
    });

    // wallets transactions
    builder.addCase(getUserWalletTransactions.pending, (state) => {
      state.walletTransactionsLoading = true;
    });
    builder.addCase(getUserWalletTransactions.fulfilled, (state, action) => {
      state.walletTransactionsLoading = false;
      state.walletTransactions = action.payload;
    });
    builder.addCase(getUserWalletTransactions.rejected, (state) => {
      state.walletTransactionsLoading = false;
    });

    // wallet transactions csvs
    builder.addCase(getWalletTransactionsCSV.pending, (state) => {
      state.walletsCsvsLoading = true;
    });
    builder.addCase(getWalletTransactionsCSV.fulfilled, (state, action) => {
      state.walletsCsvsLoading = false;
    });
    builder.addCase(getWalletTransactionsCSV.rejected, (state) => {
      state.walletsCsvsLoading = false;
    });
  },
});

export default GeneralSliceSlice.reducer;
