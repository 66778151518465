import React, { useState, useEffect, useRef } from "react";
import "./adminUser.scss";
import Layout from "../../sharedModules/defaultLayout";
import { Row, Col, Button, Avatar, Form, Spin, Input, Select, message, Table, Modal } from "antd";
import { CloudDownloadOutlined, UserOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getUsersCSV, getAllAdmins, deleteAdmin } from "../../store/users";
import AdminUser from "../../Components/forms/AdminUser";
import {
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const { Option } = Select;

const Admin = () => {
  const baseUrl = `${process.env.REACT_APP_baseURL}`;
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState();
  const [adminData, setAdminData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const { usersCSVLoading, usersCSV, allAdmins, allAdminsLoader } = useSelector(
    (state) => ({
      usersCSVLoading: state.usersReducer.usersCSVLoading,
      usersCSV: state.usersReducer.usersCSV,
      allAdmins: state.usersReducer.allAdmins,
      allAdminsLoader: state.usersReducer.allAdminLoader
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getAllAdmins())
  }, []);
  console.log(allAdmins?.data, "hhhhhhhh", allAdminsLoader)
  const showModal = (type, adminId, record) => {
    const isDelete = type === "del";
    const isEdit = type === "edit";

    setIsDelete(isDelete);
    setIsEdit(isEdit);

    if (isEdit) {
      setAdminData(record);
    }

    if (adminId) {
      setSelectedAdminId(adminId);
    }

    setIsModalOpen(true);
  };

  console.log(adminData, "is adminDataadminDataadminData")
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const cols = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (image) => {
        return !image ? (
          <Avatar
            style={{
              backgroundColor: "#87d068",
            }}
            icon={<UserOutlined />}
          />
        ) : (
          <Avatar size={34} src={<img src={`${baseUrl}${image}`} alt="avatar" />} />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (name) => <b>{name}</b>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "email",
      render: (email) => <b>{email}</b>,
    },

    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      //sorter: (a, b) => a.name.length - b.name.length,
      render: (created_at) => <b>{created_at ? dayjs(created_at).format("YYYY-MM-DD") : "-"}</b>,
    },
    {
      title: "Type",
      dataIndex: "user_type",
      key: "user_type",
      align: "center",
      render: (user_type) => (
        /*
        0 -> super admin
        4 -> admin
        5 -> compliance
        */
        <div>
          <div className={`${user_type}-table-tag`}>
            <div className={`${user_type}-dot-tag dot`} />
            {user_type == 0 ? "super admin" : user_type == 4 ? "admin" : user_type == 5 ? "compliance" : ""}
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <div className="flex items-center gap-4">
          <EditOutlined className="text-hawaiiMorning" size="lg" onClick={() => showModal("edit", record?.id, record)} />
          <DeleteOutlined className="text-emberGlow-900" size="lg" onClick={() => showModal("del", record?.id)} />
        </div>
      ),
    },
  ];

  const exportAdminUsersList = async () => {
    try {
      const res = await dispatch(getUsersCSV());
      if (res?.error) {
        return message.error(res?.payload?.error);
      } else {
        const a = document.createElement("a");
        a.href = res.payload;
        a.download = "users.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAdminHandler = () => {
    dispatch(deleteAdmin(selectedAdminId)).then(res => {
      handleCancel();
    })
  }

  return (
    <Layout>
      <Spin spinning={allAdminsLoader}>
        <div className="admin-users-container">
          <Modal
            width={900}
            centered
            className="logout-modal"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={false}
            key={isDelete ? `del-${selectedAdminId}` : isEdit ? `edit-${selectedAdminId}` : "add-admin"}
          >
            {isDelete ? <div>
              <div className="flex flex-col justify-center items-center">
                <p>Are you sure to delete this advisor?</p>
                <div className="flex justify-center items-center gap-2 mt-8">
                  <Button onClick={deleteAdminHandler} className="bg-emberGlow-900 border-emberGlow-900 text-white"
                  >Delete</Button>
                  <Button onClick={handleCancel} className="border-brilliantWhite" >Cancel</Button>
                </div>
              </div>
            </div> :
              <AdminUser cancelModal={handleCancel} adminId={selectedAdminId} initialData={isEdit ? adminData : null} edit={isEdit} />
            }
          </Modal>

          <div className="flex items-center justify-between mb-5">
            <Button icon={<PlusOutlined />} className="add-btn" onClick={() => showModal("add")}>
              ADD ADMIN
            </Button>
            <div className="investor-col-two">
              <Button
                icon={<CloudDownloadOutlined />}
                className="export-btn"
                onClick={exportAdminUsersList}
                loading={usersCSVLoading}>
                Export
              </Button>
            </div>
          </div>

          <Table dataSource={allAdmins?.data[0]} columns={cols} key="id" pagination={false} />;
        </div>
      </Spin>
    </Layout>
  );
};

export default Admin;
