import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Avatar, Form, Spin, Input, Select, message } from "antd";
import { createAdminUser, getAdminUser } from "../../services/ApiHandler";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { postAddAdmin, postUpdateAdmin } from "../../store/users";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const { Option } = Select;

const AdminUser = ({ cancelModal, adminId, initialData, edit }) => {
    console.log(initialData, "initialDatainitialData")
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { addAdminLoader, editAdminLoader } = useSelector(
        (state) => ({
            addAdminLoader: state.usersReducer.addAdminLoader,
            editAdminLoader: state.usersReducer.editAdminLoader,
        }),
        shallowEqual
    );

    const onFinish = async (values) => {
        const response = edit ? dispatch(postUpdateAdmin({
            id: adminId,
            data: values
        })) : dispatch(postAddAdmin(values));
        response.then((res) => {
            if (!res.error) {
                !edit && form.resetFields();
                cancelModal();
            } else {
                let obj = res?.payload?.error?.errors;
                let customValidation = [];
                for (let key in obj) {
                    customValidation.push({
                        name: key,
                        errors: obj[key],
                    });
                }
                form?.setFields(customValidation);
            }
        })
    };


    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            initialValues={initialData}
            form={form}
            ref={formRef}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        type: "email",
                        message: "Please input your Email!",
                    },
                ]}>
                <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
                label="Name"
                name="name"
                rules={[
                    {
                        required: true,
                        type: "name",
                        message: "Please enter your name",
                    },
                ]}>
                <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: edit ? false : true,
                        message: "Please input your password!",
                    },
                    {
                        pattern: /^.{8,}$/,
                        message: "The password must be at least 8 characters.",
                    },
                ]}>
                <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item
                label="Confirm Password"
                name="password_confirmation"
                dependencies={["password"]}
                rules={[
                    {
                        required: true,
                        message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject("The passwords do not match!");
                        },
                    }),
                ]}>
                <Input.Password placeholder="Confirm Password" />
            </Form.Item>
            <Form.Item
                label="Role"
                name="user_type"
                rules={[
                    {
                        required: true,
                        message: "Please select  role!",
                    },
                ]}
            >
                <Select placeholder="Select Role" allowClear>
                    <Option value="4">Admin </Option>
                    <Option value="5"> compliance</Option>
                </Select>
            </Form.Item>

            <Form.Item>
                <Button htmlType="submit" className="bg-primary text-white border border-primary" loading={edit ? editAdminLoader : addAdminLoader}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AdminUser;
