import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { message } from "antd";

const initialState = {
  // export loans
  usersCSV: null,
  usersCSVLoading: false,
  allAdmins: null,
  allAdminLoader: false,
  addAdminLoader: false,
  editAdminLoader: false,
  deleteAdminLoader: false
};



//^ ========= grt admins ============
export const getAllAdmins = createAsyncThunk(
  "users/getAllAdmins",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .get(`/admin/getadmins`)
        .then((response) => {
          console.log(response, "resssssssss")
          return response?.data
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      throw e;
    }
  }
);
//^ ========= add admin ============
export const postAddAdmin = createAsyncThunk(
  "users/postAddAdmin",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`admin/create_sub_admin`, data)
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response, "update");
          dispatch(getAllAdmins());
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= edit admin ============
export const postUpdateAdmin = createAsyncThunk(
  "users/postUpdateAdmin",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        // admin/update_sub_admin/{admin}
        .post(`admin/update_sub_admin/${id}`, data)
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response, "update");
          dispatch(getAllAdmins());
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= delete admin ============
export const deleteAdmin = createAsyncThunk("users/deleteAdmin", async (id, { rejectWithValue, dispatch }) => {
  try {
    return await axiosInstance
      .delete(`admin/delete_sub_admin/${id}`)
      .then((response) => {
        message.success(response?.data?.message);
        console.log(response, "delete");
        dispatch(getAllAdmins());
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.response?.data?.message || "request failed");
      });
  } catch (e) {
    throw e;
  }
});

//^ ========= get investors csv  ============ //
export const getUsersCSV = createAsyncThunk("users/getUsersCSV", async (data, { rejectWithValue }) => {
  try {
    return await axiosInstance
      .get(`/admin/getadmins/export_csv`)
      .then((response) => {
        console.log(response?.data?.data?.fileUrl, "getUsersCSV");
        return response?.data?.data?.fileUrl;
      })
      .catch((error) => {
        console.log(error);
        return rejectWithValue({
          error: error.message,
        });
      });
  } catch (e) {
    throw e;
  }
});
/*-----------------------Slice----------------*/

const usersSlice = createSlice({
  name: "investorsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // all admin
    builder.addCase(getAllAdmins.pending, (state) => {
      state.allAdminLoader = true;
    });
    builder.addCase(getAllAdmins.fulfilled, (state, action) => {
      state.allAdminLoader = false;
      state.allAdmins = action.payload;
    });
    builder.addCase(getAllAdmins.rejected, (state) => {
      state.allAdminLoader = false;
    });

    // add admins
    builder.addCase(postAddAdmin.pending, (state) => {
      state.addAdminLoader = true;
    });
    builder.addCase(postAddAdmin.fulfilled, (state, action) => {
      state.addAdminLoader = false;
    });
    builder.addCase(postAddAdmin.rejected, (state) => {
      state.addAdminLoader = false;
    });

    // edit admin
    builder.addCase(postUpdateAdmin.pending, (state) => {
      state.editAdminLoader = true;
    });
    builder.addCase(postUpdateAdmin.fulfilled, (state, action) => {
      state.editAdminLoader = false;
    });
    builder.addCase(postUpdateAdmin.rejected, (state) => {
      state.editAdminLoader = false;
    });

    // delete admin
    builder.addCase(deleteAdmin.pending, (state) => {
      state.deleteAdminLoader = true;
    });
    builder.addCase(deleteAdmin.fulfilled, (state, action) => {
      state.deleteAdminLoader = false;
    });
    builder.addCase(deleteAdmin.rejected, (state) => {
      state.deleteAdminLoader = false;
    });

    // investors csv
    builder.addCase(getUsersCSV.pending, (state) => {
      state.usersCSVLoading = true;
    });
    builder.addCase(getUsersCSV.fulfilled, (state, action) => {
      state.usersCSVLoading = false;
      state.usersCSV = action.payload;
    });
    builder.addCase(getUsersCSV.rejected, (state) => {
      state.usersCSVLoading = false;
    });
  },
});

export default usersSlice.reducer;
