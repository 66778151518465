import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { message } from "antd";

const initialState = {
  allFaqsLoading: false,
  singleFaqLoading: false,
  createFaqLoading: false,
  updateFaqLoading: false,
  deleteFaqLoading: false,
  faqs: [],
  singleFaq: null,
};

//^ ========= get faqs ============//
export const getAllFaqs = createAsyncThunk("faqs/getAllFaqs", async (data, { rejectWithValue }) => {
  try {
    return await axiosInstance
      .get("admin/faqs")
      .then((response) => {
        return response?.data?.data || [];
      })
      .catch((error) => {
        console.log(error);
        // return rejectWithValue({
        //   message: error.message,
        //   error: error.response.data,
        // });
      });
  } catch (e) {
    throw e;
  }
});

//^ ========= view faqs ============ //
// export const getSingleFaqs = createAsyncThunk("faqs/getSingleFaqs", async ({ id }, { rejectWithValue }) => {
//   try {
//     return await axiosInstance
//       .get(`admin/faqs/${id}`)
//       .then((response) => {
//         console.log(response);
//         return response.data;
//       })
//       .catch((error) => {
//         console.log(error);
//         // return rejectWithValue({
//         //   message: error.message,
//         //   error: error.response.data,
//         // });
//       });
//   } catch (e) {
//     throw e;
//   }
// });

//^ ========= create faqs ============
export const postCreateFaqs = createAsyncThunk("faqs/postCreateFaqs", async (data, { rejectWithValue, dispatch }) => {
  try {
    return await axiosInstance
      .post(`admin/faqs/store`, data)
      .then((response) => {
        message.success(response?.data?.message);
        console.log(response, "post");
        dispatch(getAllFaqs());
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.response?.data?.message);
      });
  } catch (e) {
    throw e;
  }
});

//^ ========= update faqs ============
export const postUpdateFaqs = createAsyncThunk(
  "faqs/postUpdateFaqs",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`admin/faqs/update/${id}`, data)
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response, "update");
          dispatch(getAllFaqs());
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= delete faqs ============
export const deleteSingleFaqs = createAsyncThunk("faqs/deleteSingleFaqs", async (id, { rejectWithValue, dispatch }) => {
  try {
    return await axiosInstance
      .delete(`admin/faqs/destroy/${id}`)
      .then((response) => {
        message.success(response?.data?.message);
        console.log(response, "delete");
        dispatch(getAllFaqs());
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.response?.data?.message || "request failed");
      });
  } catch (e) {
    throw e;
  }
});
/*-----------------------Slice----------------*/

const faqsSlice = createSlice({
  name: "faqsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get faqs
    builder.addCase(getAllFaqs.pending, (state) => {
      state.allFaqsLoading = true;
    });
    builder.addCase(getAllFaqs.fulfilled, (state, action) => {
      console.log(action.payload, "action.payload");
      state.allFaqsLoading = false;
      state.faqs = action.payload;
    });
    builder.addCase(getAllFaqs.rejected, (state) => {
      state.allFaqsLoading = false;
    });

    // //  view single faqs
    // builder.addCase(getSingleFaqs.pending, (state) => {
    //   state.singleFaqLoading = true;
    // });
    // builder.addCase(getSingleFaqs.fulfilled, (state, action) => {
    //   state.singleFaqLoading = false;
    // });
    // builder.addCase(getSingleFaqs.rejected, (state) => {
    //   state.singleFaqLoading = false;
    // });

    // post faqs
    builder.addCase(postCreateFaqs.pending, (state) => {
      state.createFaqLoading = true;
    });
    builder.addCase(postCreateFaqs.fulfilled, (state, action) => {
      state.createFaqLoading = false;
    });
    builder.addCase(postCreateFaqs.rejected, (state) => {
      state.createFaqLoading = false;
    });

    // update faqs
    builder.addCase(postUpdateFaqs.pending, (state) => {
      state.updateFaqLoading = true;
    });
    builder.addCase(postUpdateFaqs.fulfilled, (state, action) => {
      state.updateFaqLoading = false;
    });
    builder.addCase(postUpdateFaqs.rejected, (state) => {
      state.updateFaqLoading = false;
    });

    // delete faqs
    builder.addCase(deleteSingleFaqs.pending, (state) => {
      state.deleteFaqLoading = true;
    });
    builder.addCase(deleteSingleFaqs.fulfilled, (state, action) => {
      state.deleteFaqLoading = false;
    });
    builder.addCase(deleteSingleFaqs.rejected, (state) => {
      state.deleteFaqLoading = false;
    });
  },
});

export default faqsSlice.reducer;
