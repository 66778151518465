import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { message } from "antd";

const initialState = {
  // end Of Days Statements
  EODStatements: null,
  EODStatementsLoading: false,
  // end Of Days single Statement
  EODSingleStatement: null,
  EODSingleStatementLoading: false,
  // account balance
  accountBalance: null,
  accountBalanceLoading: false,
  // account statement
  accountStatement: null,
  accountStatementLoading: false,

  // export investors
  statementsCSVLoading: false,
};

//^ ========= get EOD statements ============
export const getEODStatements = createAsyncThunk("fundsManagement/getEODStatements", async (_, { rejectWithValue }) => {
  try {
    return await axiosInstance
      .get(`/admin/anb/getEndOfDaysStatements`)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    throw e;
  }
});
//^ ========= get EOD single statement ============
export const getEODSingleStatement = createAsyncThunk(
  "fundsManagement/getEODStatement",
  async (id, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`/admin/anb/getEndOfDaysStatement/${id}`)
        .then((response) => {
          return response?.data?.data;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      throw e;
    }
  }
);
//^ ========= get account balance ============
export const getAccountBalance = createAsyncThunk(
  "fundsManagement/getAccountBalance",
  async (id, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`/admin/anb/getAccountBalance`)
        .then((response) => {
          return response?.data?.data;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= get account statements ============
export const getAccountStatement = createAsyncThunk(
  "fundsManagement/getAccountStatement",
  async (params, { rejectWithValue }) => {
    console.log(params, "hhhhhhhhhh")
    try {
      return await axiosInstance
        .get(`/admin/anb/getAccountStatement`, { params })
        .then((response) => {
          return response?.data?.data;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= get statements csv  ============ //
export const getStatementsCSV = createAsyncThunk("fundsManagement/getStatementsCSV", async () => {
  try {
    return await axiosInstance
      .get(`admin/anb/exportAccountStatement`)
      .then((response) => {
        console.log(response, "getInvestorsCSV");
        const link = document.createElement("a");
        link.href = response?.data?.data?.fileUrl;
        link.download = "investors.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return response?.data?.data?.fileUrl;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    throw e;
  }
});
/*-----------------------Slice----------------*/
const fundsManagement = createSlice({
  name: "fundsManagementSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // EOD statements
    builder.addCase(getEODStatements.pending, (state) => {
      state.EODStatementsLoading = true;
    });
    builder.addCase(getEODStatements.fulfilled, (state, action) => {
      state.EODStatementsLoading = false;
      state.EODStatements = action.payload;
    });
    builder.addCase(getEODStatements.rejected, (state) => {
      state.EODStatementsLoading = false;
    });

    // EOD single statement
    builder.addCase(getEODSingleStatement.pending, (state) => {
      state.EODSingleStatementLoading = true;
    });
    builder.addCase(getEODSingleStatement.fulfilled, (state, action) => {
      state.EODSingleStatementLoading = false;
      state.EODSingleStatement = action.payload;
    });
    builder.addCase(getEODSingleStatement.rejected, (state) => {
      state.EODSingleStatementLoading = false;
    });

    // account balance
    builder.addCase(getAccountBalance.pending, (state) => {
      state.accountBalanceLoading = true;
    });
    builder.addCase(getAccountBalance.fulfilled, (state, action) => {
      state.accountBalanceLoading = false;
      state.accountBalance = action.payload;
    });
    builder.addCase(getAccountBalance.rejected, (state) => {
      state.accountBalanceLoading = false;
    });

    // account statements
    builder.addCase(getAccountStatement.pending, (state) => {
      state.accountStatementLoading = true;
    });
    builder.addCase(getAccountStatement.fulfilled, (state, action) => {
      state.accountStatementLoading = false;
      state.accountStatement = action.payload;
    });
    builder.addCase(getAccountStatement.rejected, (state) => {
      state.accountStatementLoading = false;
    });
  },
});

export default fundsManagement.reducer;
